<template>
    <div class="wrap-detail-deposit">
        <div class="content">
            <div class="title-content">
                <div class="card description-deposit">
                    <div>
                        <div class="card-content">
                            <span>Transaction ID</span>
                            <span>{{ detailDeposit.transaction_id }}</span>
                        </div>
                        <div class="card-content">
                            <span>Deposit Date</span>
                            <span>{{ detailDeposit.deposit_date | formatDateTime }}</span>
                        </div>
                        <div class="card-content">
                            <span>Total Item</span>
                            <span>{{ detailMetalItem ? detailMetalItem.total_item : "" }}</span>
                        </div>
                        <div class="card-content">
                            <span>Total Weight</span>
                            <span>{{ detailMetalItem ? detailMetalItem.total_weight : "" }}</span>
                        </div>
                        <div class="card-content">
                            <span>FTZ No</span>
                            <span>{{ detailDeposit.documents ? detailDeposit.documents.ftz_doc_no : "" }}</span>
                        </div>
                        <div class="card-content">
                            <span>Recomendation No</span>
                            <span>{{ detailDeposit.documents ? detailDeposit.documents.recommendation_doc_no : "" }}</span>
                        </div>
                        <div class="card-content">
                            <span>Status</span>
                            <span v-if="detailDeposit.status == '001'" class="abx">Request Deposit</span>
                            <span v-else-if="detailDeposit.status == '002'" class="operator">Operator Approve</span>
                            <span v-else-if="detailDeposit.status == '003'" class="clearing">Clearing Confirmation</span>
                            <span v-else-if="detailDeposit.status == '004'" class="stored">KMI Confirmation (Stored)</span>
                        </div>
                    </div>
                    <button type="button" class="btn btn-primer" @click="openModal" v-if="role == 5 || detailDeposit == '001'">Add Attachment</button>
                </div>
                <div class="card">
                    <div class="title-content">Metal Info</div>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col" style="width: 70px">No</th>
                                <th scope="col">Depositor</th>
                                <th scope="col">Dimension ( mm<sup>3</sup> )</th>
                                <th scope="col">Fineness</th>
                                <th scope="col">Hallmark</th>
                                <th scope="col">Weight (gr)</th>
                                <th scope="col">Serial Number</th>
                                <th scope="col">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(metal, i) in detailDeposit.metal_info" :key="i">
                                <td>{{ i + 1 }}</td>
                                <td>{{ metal.owner }}</td>
                                <td>{{ metal.dimension }}</td>
                                <td>{{ metal.fineness }}</td>
                                <td>{{ metal.hallmark }}</td>
                                <td>{{ metal.weight }}</td>
                                <td>{{ metal.serial_number }}</td>
                                <td>{{ metal.item_description }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card">
                    <div class="title-content">Attachment</div>
                    <table>
                        <thead>
                            <th>Commercial Invoice</th>
                            <th>Customs</th>
                            <th>Manufacturing</th>
                            <th>Recomendation</th>
                            <th>Vault</th>
                        </thead>
                        <tbody>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-info-outline btn-sm"
                                    v-if="detailDeposit.attachments.commercial_invoice"
                                    @click="openPreview(detailDeposit.attachments.commercial_invoice, 'Commercial Invoice Documentation')"
                                >
                                    View
                                </button>
                                <span v-else>No Data</span>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-info-outline btn-sm"
                                    v-if="detailDeposit.attachments.customs_doc"
                                    @click="openPreview(detailDeposit.attachments.customs_doc, 'Customs Documentation')"
                                >
                                    View
                                </button>
                                <span v-else>No Data</span>
                            </td>

                            <td>
                                <button
                                    type="button"
                                    class="btn btn-info-outline btn-sm"
                                    v-if="detailDeposit.attachments.manufacturing_doc"
                                    @click="openPreview(detailDeposit.attachments.manufacturing_doc, 'Manufacturing Documentation')"
                                >
                                    View
                                </button>
                                <span v-else>No Data</span>
                            </td>

                            <td>
                                <button
                                    type="button"
                                    class="btn btn-info-outline btn-sm"
                                    v-if="detailDeposit.attachments.recommendation_doc"
                                    @click="openPreview(detailDeposit.attachments.recommendation_doc, 'Recomendation Documentation')"
                                >
                                    View
                                </button>
                                <span v-else>No Data</span>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-info-outline btn-sm"
                                    v-if="detailDeposit.attachments.vault_doc"
                                    @click="openPreview(detailDeposit.attachments.vault_doc, 'Vault Documentation')"
                                >
                                    View
                                </button>
                                <span v-else>No Data</span>
                            </td>
                        </tbody>
                    </table>
                    <b-modal ref="preview-modal" size="lg" :title="labelPreview">
                        <div class="d-block text-center">
                            <div v-if="isPdf">
                                <b-spinner label="Loading..." v-if="isLoadingPreview"></b-spinner>
                                <pdf v-for="i in numPages" :key="i" :src="src" :page="i" v-else />
                            </div>
                            <div v-else>
                                <b-spinner label="Loading..." v-if="isLoadingPreview"></b-spinner>
                                <img :src="src" alt="" style="width: 100%; object-fit: cover" v-else />
                            </div>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import pdf from "vue-pdf";
export default {
    components: {
        pdf,
    },
    props: {
        detailDeposit: Object,
        detailMetalItem: Object,
    },
    data() {
        return {
            role: sessionStorage.getItem("role"),
            labelPreview: "",
            isPdf: false,
            isLoadingPreview: false,
            numPages: undefined,
            src: null,
        };
    },
    methods: {
        openPreview(file, label) {
            this.$refs["preview-modal"].show();
            this.labelPreview = label;
            this.isLoadingPreview = true;
            let newFile = file.replace("/kmi", "/vms");
            this.getImage(newFile)
                .then((res) => {
                    if (file.split(".").pop() == "pdf") {
                        this.isPdf = true;
                        this.isLoadingPreview = false;
                        this.src = pdf.createLoadingTask(res);
                        this.src.promise.then((pdf) => {
                            this.numPages = pdf.numPages;
                        });
                    } else {
                        this.isLoadingPreview = false;
                        this.isPdf = false;
                        this.src = res;
                    }
                })
                .catch(() => {
                    this.isLoadingPreview = false;
                });
        },
        async getImage(file) {
            let image = await axios.get("/v2" + file, { responseType: "arraybuffer" });
            image = URL.createObjectURL(new Blob([image.data], { type: image.data.type }));
            return image;
        },
        openModal() {
            this.$emit("updateModal", true);
        },
    },
};
</script>

<style scoped>
.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.card {
    padding: 1rem;
    margin-bottom: 1rem;
}

.description-deposit {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.btn-primer {
    height: 2.5rem;
}

.card-content {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
}

.card-content span:first-child {
    width: 9rem;
}

.card-content span:last-child {
    font-weight: bold;
}

.clearing {
    /* background: rgba(50, 90, 115, 0.1); */
    font-weight: 600;
    color: rgb(50, 90, 115);
    border-radius: 5px;
}
.operator {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(252, 108, 4);
    border-radius: 5px;
}
.abx {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(172, 143, 26);
    border-radius: 5px;
}
.stored {
    /* background: rgba(40, 167, 69, 0.1); */
    font-weight: 600;
    color: rgb(40, 167, 69);
    border-radius: 5px;
}
</style>
